<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-drawer title="保单详情" placement="right" :closable="false" width="80%" v-model:visible="visible">
    <iframe v-if="insuranceData.fileUrl != null && insuranceData.fileUrl.length > 1" :src="insuranceData.fileUrl"
      :style="iframeStyle"></iframe>
  </a-drawer>
</template>
<script>
import { toRefs, reactive } from 'vue'
import { detail } from '@/api/system/insurance'
export default {
  setup () {
    const state = reactive({
      visible: false,
      iframeStyle: {
        width: '100%',
        height: '100%'
      },
      orderId: '',
      insuranceData: {}
    })

    const insuranceDetail = (orderId, visible) => {
      state.orderId = orderId
      state.visible = visible
      detail(orderId).then(res => {
        if (res.code === 10000) {
          state.insuranceData = res.data
          getHeight()
        }
      })
    }
    const getHeight = () => {
      state.iframeStyle.height = window.innerHeight - 64 - 68 + 'px'
    }
    return {
      ...toRefs(state),
      insuranceDetail
    }
  }
}
</script>
